




























import { Component, Mixins, Prop, Watch, Ref } from 'vue-property-decorator';
import { TranslateMixin } from '@/util/translate';

import FFile from '@/components/form/f-file.vue';
import FSelect from '@/components/form/f-select.vue';
import FInput, { FormatType } from '@/components/form/f-input.vue';
import {
    FormChildren,
    FormChildType, FormChoiceChild,
    FormElementInterface,
    FormItemChoice,
    InputValueTypes,
    isChoiceType,
    OptionFilterFunc,
} from '@/components/form/serverForm';
import { clone } from 'lodash';
import FormElementMixin from '@/components/form/FormElementMixin.vue';
import FCheckbox from '@/components/form/f-checkbox.vue';
import FDatepicker from '@/components/form/f-datepicker.vue';
import FormAwareMixin from '@/components/ui/mixin/form-aware-mixin.vue';
import FServerFormChildPlaceholders from '@/components/form/f-server-form-child-placeholders.vue';
import PlaceholderOptions, { defaultPlaceholderOptions } from '@/components/form/placeholders';
import FTextArea from '@/components/form/f-text-area.vue';
import TabValidationAwareMixin from '@/components/ui/mixin/tab-validation-aware-mixin.vue';
import UIChangeTriggerController from '@/components/form/UIChangeTrigger';

@Component({
    components: {
        FServerFormChildPlaceholders,
    }
})
export default class FServerFormChild extends Mixins(FormElementMixin, TranslateMixin, FormAwareMixin, TabValidationAwareMixin) {
    @Ref()
    formElement!: FormElementInterface;

    @Prop({ required: true })
    child!: FormChildren;

    @Prop({ required: false, type: String })
    id!: string|undefined;

    @Prop({ type: Boolean, default: false })
    hideLabel!: boolean;

    @Prop({ required: true })
    value!: InputValueTypes;

    @Prop({ default: null })
    type!: FormChildType|null;

    @Prop({ required: false, type: Function, default: null })
    optionsFilter!: OptionFilterFunc|null;

    @Prop({ type: Boolean, default: false, required: false })
    allowCreate!: boolean;

    @Prop({ type: Boolean, default: false, required: false })
    invalid!: boolean;

    @Prop({ type: Boolean, default: false })
    readonly!: boolean;

    @Prop({ type: String, default: null })
    name!: string;

    @Prop({ type: Boolean, default: false })
    disableAutomaticLinks!: boolean;

    innerValue: InputValueTypes = '';
    actualChoices: Array<FormItemChoice> = [];
    triggerController = new UIChangeTriggerController();
    showChangeIndicator = false;
    showChangeIndicatorTimeout = 0;
    errorState = {
        valid: true
    };

    get childName(): string {
        if (this.name) return this.name;
        return this.child.name;
    }

    get isCheckboxType(): boolean {
        return this.child?.childType === FormChildType.CHECKBOX;
    }

    mounted() {
        this.onFormInitialized(() => {
            this.formContext.registerChild(this.childName);

            this.initialize();

            this.onFormFieldValidationChange(this.child.label, (event) => {
                this.errorState.valid = event.valid;
                this.setParentTabItemErrorState(event.valid);
            });

            this.onFormFieldTrigger(this.child, (event) => this.triggerController.handleTrigger(event.trigger));

            if (!this.formContext.initialElementIsFocused() && !this.value && this.formElement) {
                const element = this.formElement.getInputElement();

                if (element) {
                    element.focus();
                    this.formContext.setInitialElementIsFocused(true);
                }
            }

            this.$nextTick(() => {
                this.formContext.childFinishedInitializing(this.childName);
                this.$emit('initialized');
            });
        });
    }

    initialize() {
        if (this.isCheckboxType) {
            this.innerValue = Boolean(clone(this.value));
        } else {
            this.innerValue = clone(this.value);
        }

        if (isChoiceType(this.child)) {
            this.actualChoices = [...this.child.choices];
        }
    }

    get formComponent() {
        if (!this.formIsInitialized) {
            return null;
        }

        switch(this.child.childType) {
            case FormChildType.COLOR:
            case FormChildType.NUMBER:
            case FormChildType.TEXT:
                return FInput;
            case FormChildType.TEXT_AREA: return FTextArea;
            case FormChildType.CHECKBOX: return FCheckbox;
            case FormChildType.SELECT: return FSelect;
            case FormChildType.DATE: return FDatepicker;
            case FormChildType.DATE_TIME: return FDatepicker;
            case FormChildType.FILE: return FFile;
        }
    }

    get componentProps(): Record<string, any> {
        if (!this.formIsInitialized) {
            return {};
        }

        const commonOptions = {
            helpText: this.child.help,
            id: this.id || this.child.id,
            translated: true,
            readonly: this.readonly,
            isValid: this.$attrs.hasOwnProperty('is-valid') || false,
            invalid: this.invalid,
            ...this.child.attr,
            ...this.formElementStateProps,
            ...this.triggerController.getFormChildProps(),
        };

        // Inherit data attributes.
        for (const key in this.$attrs) {
            if (key.startsWith('data-')) {
                commonOptions[key] = this.$attrs[key];
            }
        }

        if (!this.hideLabel) {
            commonOptions['label'] = this.label || this.$t(this.child.label).toString();
        } else {
            commonOptions['show-label'] = false;
        }

        if (!commonOptions.required) {
            commonOptions.required = this.child.required;
        }

        if (this.child.childType === FormChildType.COLOR) {
            return Object.assign({}, commonOptions, {
                type: 'color',
            });
        }

        if (this.child.childType === FormChildType.CHECKBOX) {
            return Object.assign({}, commonOptions, {
                disabled: this.child.disabled,
            });
        }

        if (this.child.childType === FormChildType.NUMBER) {
            return Object.assign({}, commonOptions, {
                formatType: FormatType.NUMBER,
            });
        }

        if (this.child.childType === FormChildType.SELECT && isChoiceType(this.child)) {
            let options: {label?: string}[] = (this.$attrs.options ?? this.child.choices) as any;

            if (Array.isArray(options) === false) {
                options = Object.values(options);
            }

            const optionFilter = this.optionsFilter;
            if (optionFilter) {
                options = options.filter((choice) => {
                    return optionFilter(choice);
                });

                this.$emit('options-filtered', this.name);
            }

            // if (this.child.editLinkRoute) {
            //     commonOptions['editLinkRoute'] = this.$path(this.child.editLinkRoute, this.child.editLinkRouteAttributes ?? {});
            // }

            const disableLinks = this.disableAutomaticLinks || this.hideLabel

            return Object.assign({}, commonOptions, {
                options: options,
                optionLabel: this.$attrs['option-label'] ?? 'label',
                optionValue: this.$attrs['option-value'] ?? 'value',
                disabled: Boolean(this.child.disabled || this.disabled),
                multiple: this.child.multiple,
                allowCreate: this.allowCreate,
                lazyLoaded: this.child.lazyLoaded ?? false,
                lazyLoadTrigger: this.child.lazyLoadTrigger ?? 'onClick',
                listEntityLink: !disableLinks ? (this.child.listEntityLink ?? null) : null,
                updateEntityLink: !disableLinks ? (this.child.updateEntityLink ?? null) : null,
                name: this.childName
            });
        }

        return commonOptions;
    }

    onValueChange(value: InputValueTypes) {
        this.$emit('input', typeof value == 'boolean' ? (value ? '1' : '') : value);
    }

    changeObject(object: FormItemChoice): void {
        this.$emit('change-object', object);
        const formModel = this.formContext.getFormModel();

        if (formModel) {
            formModel.valueObjects[this.childName] = object;
        }
    }

    loadLazyChoices() {
        if (this.child.lazyLoaded) {
            this.formContext.lazyLoadedChoices(this.child as any as FormChoiceChild);
        }
    }

    getDefaultPlaceholderOptions(): PlaceholderOptions {
        const map = this.formContext.getChildPlaceholderMap();

        if (map) {
            const textType = map[FormChildType.TEXT];

            if (textType) {
                return textType;
            }
        }

        return defaultPlaceholderOptions;
    }

    @Watch('value', { immediate: true })
    onExternalValueChange(value: InputValueTypes) {
        if (this.child && this.isCheckboxType) {
            this.innerValue = Boolean(value);
        } else {
            this.innerValue = value;
        }
    }
}
